$(function () {

    var popup = $('.alert-popup');
    popup.on('click', function () {
        $(this).fadeOut();
    });
    if (popup.length) {
        setTimeout(function () {
            popup.fadeOut();
        }, 3000);
    }


    $('.head__menu__hamburger, .slide__menu__close').on('click', function () {
        $('.slide__menu').toggleClass('opened');
    });


    $('input.product-quantity').on('blur', function () {
        $('#submit-recalculate').click();
    });

    $('.cart__item_qty_key').on('click', function () {
        var id = $(this).data('id');
        var action = $(this).data('action');
        var input = $('#product_qty_' + id);
        var val = parseInt(input.val());

        if (action === 'minus') {
            val--;
        }
        if (action === 'plus') {
            val++;
        }
        if (val <= 0) {
            val = 1;
        }

        input.val(val);
        $('#submit-recalculate').click();

        return false;
    });


    $('.add-to-cart').on('click', function () {
        var el = $(this);
        var item_id = parseInt(el.data('id'));
        var variants = $('#variants-' + item_id);
        var variant_id = variants.length ? variants.val() : 0;

        var button_background = $(this).css('background-color');
        var button_text = $(this).text();
        var text_color = $(this).css('color');
        el.css('color', button_background);

        if (!variants.length || variant_id > 0) {
            addProduct(el, item_id, variant_id, text_color);
        } else {

            var modalContent = $('<div>');
            modalContent.append('<h4>Выберите вид товара:</h4>');
            modalContent.append(variants);

            var submit = $('<button>', {class: 'btn btn-success'}).text('Купить');
            var buttonContainer = $('<div>', {class: 'space-mini-top text-right'});
            buttonContainer.append(submit);
            modalContent.append(buttonContainer);

            var modal = new modalbox({
                width: 350,
                onClose: function (modalbox) {
                    console.log(modalbox);
                    variants.val(0);
                    el.text(button_text);
                    el.css('color', text_color);
                    $('#product-buttons-' + item_id).append(variants);
                }
            });

            modal.open(modalContent);

            submit.on('click', function () {
                var variant_id = variants.val();
                if (variant_id > 0) {
                    addProduct(el, item_id, variant_id, text_color);
                    modal.close();
                }
            });
        }

        return false;
    });

    function addProduct(el, product_id, variant_id, text_color) {

        var cont = $('.response-container');

        $.get('/order/add-to-cart/', {
                "id": product_id,
                "variant": variant_id
            },
            function (data) {
                setTimeout(function () {
                    el.text(data.status.text);
                    el.css('color', text_color);
                }, 1000);

                if (data.status.code === 1 && cont.length) {
                    cont.show(500);
                }
            }, 'json');
    }


    $('.product__card__images_nav').on('click', 'img', function () {
        $('.product__card__images_main img').attr('src', $(this).data('src'));
        $('.product__card__images_nav img').removeClass('active');
        $(this).addClass('active');
    });

    $('.product__card__images_main').on('click', 'img', function () {
        var active = 0;
        var total = $('.product__card__images_nav').find('img').length;

        if (total > 1) {
            $('.product__card__images_nav').find('img').each(function (i, value) {
                if ($(this).hasClass('active')) {
                    active = i;
                }
            });
        }
        var newI = active + 1;
        if (newI > total - 1) {
            newI = 0;
        }

        $('.product__card__images_nav img').removeClass('active');
        var nextImg = $('.product__card__images_nav img').eq(newI);
        nextImg.addClass('active');
        $('.product__card__images_main').find('img').attr('src', nextImg.data('src'));
    });

    $('.navigation').on('click', '.menu-toggler', function () {
        $('.menu-panel').addClass('open');
    }).on('click', '.menu-panel-close', function () {
        $('.menu-panel').removeClass('open');
    });

    if ($('.product__card__images').find('li').length > 1) {
        $('.product__card__images').bxSlider({
            pager: true,
            controls: true,
            adaptiveHeight: true,
            pagerCustom: $('#bx-pager').length ? '#bx-pager' : false
        });
    }

    var screenWidth = $(window).width();

    var promoSlider = $('#slider').bxSlider({
        auto: true,
        pause: 5000,
        pager: true,
        autoHover: true,
        controls: true,
        touchEnabled: true,
        adaptiveHeight: false
    });

    var articlesSlides = 1;
    if (screenWidth >= 600) {
        articlesSlides = 2;
    }
    if (screenWidth >= 1200) {
        articlesSlides = 3;
    }

    var articlesSlideWidth = $('#aticles_slider').innerWidth() / articlesSlides;

    articlesSlider = $('#aticles_slider').bxSlider({
        auto: false,
        controls: true,
        pager: false,
        touchEnabled: true,
        adaptiveHeight: false,
        maxSlides: 3,
        slideWidth: articlesSlideWidth
    });


    var productSlides = 1;
    if (screenWidth >= 540) {
        productSlides = 2;
    }
    if (screenWidth >= 780) {
        productSlides = 3;
    }
    if (screenWidth >= 1200) {
        productSlides = 4;
    }

    var productSlideWidth = $('#product_slider').innerWidth() / productSlides;

    var productSlider = $('#product_slider').bxSlider({
        auto: false,
        controls: true,
        pager: false,
        touchEnabled: true,
        adaptiveHeight: false,
        maxSlides: 4,
        slideWidth: productSlideWidth
    });


    $('.callback').on('click', function () {
        var modal = new modalbox();
        modal.open('подождите');

        $.post('/callback/', {}, function (data) {
            modal.fill(data);
        });
    });


});