$(function () {

    $('#head__search').on('keyup', '.head__search__input', function (e) {

        var suggestBox = $('#head__search .head__search__autosuggest');

        var keycode = parseInt(e.which);

        //console.log(keycode);

        // Esc
        if (keycode === 27) {
            suggestBox.addClass('hidden');
            return false;
        }

        var total = suggestBox.find('li').length;
        var active = suggestBox.find('li.active').index();

        // Down
        if (keycode === 40) {
            if (active === -1) {
                active = 0;
            } else {
                active++;
            }

            if (active >= (total)) {
                active = 0;
            }

            suggestBox.find('li').removeClass('active').eq(active).addClass('active');
            return false;
        }

        // Up
        if (keycode === 38) {
            if (active === -1) {
                active = total - 1;
            } else {
                active--;
            }

            if (active < 0) {
                active = total - 1;
            }

            suggestBox.find('li').removeClass('active').eq(active).addClass('active');
            return false;
        }

        // Enter
        if (keycode === 13) {
            if (active >= 0) {
                var activeItem = suggestBox.find('li').eq(active);
                var activeItemLink = activeItem.find('a');
                if (activeItemLink.length > 0) {
                    window.location = activeItemLink.attr('href');
                }
            }

            return false;
        }

        var query = $(this).val().replace(/^\s+|\s+$/g, '');

        if (!query) {
            suggestBox.addClass('hidden');
            return;
        }

        $.post('/catalog/search/', {
            q: query
        }, function (data) {
            suggestBox.html('');

            if (data.length) {
                for (var i in data) {
                    var item = data[i];
                    var listItem = $('<li>');
                    if (item.url) {
                        var link = $('<a>', {href: item.url}).html(item.label);
                        listItem.append(link);
                    } else {
                        listItem.append(item.label);
                        if (item.price) {
                            var price = $('<span>').text(item.price);
                            listItem.append(price);
                        }
                    }
                    suggestBox.append(listItem);
                }
                suggestBox.removeClass('hidden');
            }
        }, 'json');
    });

});