function modalbox(params) {
    var defaults = {
        width: 400,
        top: 100,
        height: false,
        prefix: false,
        onClose: function () {
        },
        modalBgClass: 'modal-bg',
        modalWindowClass: 'modal-window'
    };
    this.options = $.extend({}, defaults, params);
    this.options.margin = parseInt(this.options.width) / 2;
    this.elements = {};
}

modalbox.prototype.open = function (data) {
    var that = this;
    var body = $('body');

    this.elements.bg = $('<div>', {class: this.options.modalBgClass});
    this.elements.window = $('<div>', {class: this.options.modalWindowClass});
    this.elements.closeButton = $('<span>', {class: 'fas fa-times float-right space-mini cursor-pointer'});
    this.elements.content = $('<div>', {class: 'modal-cont'});

    if (this.options.width.toString().substr(-1, 1) !== '%') {
        this.options.width = this.options.width.toString() + 'px';
    }

    if (this.options.top && this.options.top.toString().substr(-1, 1) !== '%') {
        this.options.top = this.options.top.toString() + 'px';
    }

    if (this.options.margin && this.options.margin.toString().substr(-1, 1) !== '%') {
        this.options.margin = this.options.margin.toString() + 'px';
    }

    var height = 'auto';
    if (this.options.height) {
        if (this.options.height.toString().substr(-1, 1) !== '%') {
            this.options.height = this.options.height.toString() + 'px';
        }

        height = 'height: ' + this.options.height.toString() + ';';
    }

    this.elements.window.css({
        width: this.options.width,
        height: height,
        top: this.options.top,
        'margin-left': '-' + this.options.margin
    });

    this.elements.window.append(this.elements.closeButton);
    this.elements.window.append(this.elements.content);

    body
        .append(this.elements.bg)
        .append(this.elements.window);

    if (data) {
        this.fill(data);
    }

    $(that.elements.closeButton).on('click', function () {
        that.close();
    });

    $(that.elements.bg).on('click', function () {
        that.close();
    });

    return false;
};

modalbox.prototype.fill = function (data) {
    this.elements.content.html(data);
};

modalbox.prototype.close = function () {
    if (this.options.onClose) {
        eval(this.options.onClose)(modalbox);
    }

    this.elements.bg.remove();
    this.elements.window.remove();

    return false;
};